import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { BioHero, Layout } from '@components';
import { getKeyValue, compileRichText } from '@utils';
import { MEDIUM, LIGHT, DARK } from '@utils/vars';
import {
  PageWrapper, DesktopImageWrapper, MobileContent,
  PageContentWrapper, DesktopContentColumn, DesktopImageColumn,
} from './bio.styles';

const BioTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, globalSettings,
  } = data;

  const { locale } = pageContext;
  const { title: siteTitle, social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    pageTitle, contentEntry, languages, pageDescription, metaImage, pageUrl,
    disableDonateBar, disableAlertBar, disableSplash, doNotIndex,
  } = page || {};
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const {
    title, heroImage, intro, desktopMedia, desktopContent, mobileContentAndMedia,
  } = contentEntry || {};

  const {
    navigation, enableAlertBar, alertBarLink, alertBarText, footerDisclaimer,
    footerSecondaryLinks, footerPrimaryLinks, footerSocialLinks, footerCopyright, footerAddress,
    enableDonateBar, donationBar, donateBarDisclaimer, enableSplash, splash, footerForm,
    footerEmails, footerPhoneNumber, formLabelLanguageOverrides, footerDonateByMail,
  } = globalSettings || {};

  const footerProps = {
    footerCopyright,
    footerDisclaimer,
    footerSocialLinks,
    footerPrimaryLinks,
    footerSecondaryLinks,
    footerForm,
    footerAddress,
    footerEmails,
    footerPhoneNumber,
    formLabelLanguageOverrides,
    footerDonateByMail,
  };

  const donateBarProps = {
    ...donationBar,
    donateBarDisclaimer,
  };

  const alertBarProps = {
    alertBarLink,
    alertBarText,
  };

  const splashProps = {
    ...splash,
    donateBarDisclaimer,
    formLabelLanguageOverrides,
  };

  const navProps = {
    ...navigation,
    mobNavTheme: LIGHT,
  };

  return (
    <Layout
      location={location}
      navigation={navProps}
      languages={languages}
      footerProps={footerProps}
      donateBarProps={donateBarProps}
      alertBarProps={alertBarProps}
      enableAlertBar={enableAlertBar && !disableAlertBar}
      enableDonateBar={enableDonateBar && !disableDonateBar}
      enableSplash={enableSplash && !disableSplash}
      metaDescription={pageDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${pageUrl}`}
      metaTitle={`${pageTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      splashProps={splashProps}
      locale={locale}
      navTheme={DARK}
      doNotIndex={doNotIndex}
      fullWidth
    >
      <BioHero
        title={title}
        intro={intro}
        heroImage={heroImage}
      />
      <PageWrapper>
        <PageContentWrapper>
          {desktopContent && (
            <DesktopContentColumn>
              {compileRichText({ textTheme: 'primary', size: MEDIUM, locale })(desktopContent)}
            </DesktopContentColumn>
          )}
          {desktopMedia && (
            <DesktopImageColumn>
              {desktopMedia.map((media) => (
                <DesktopImageWrapper>
                  <Img
                    alt={media.description}
                    fluid={media.fluid}
                    className="desktop-image"
                  />
                </DesktopImageWrapper>
              ))}
            </DesktopImageColumn>
          )}
          {mobileContentAndMedia && (
            <MobileContent>
              {mobileContentAndMedia.map((content) => {
                const { leftColumn, rightMedia, id } = content;
                const { id: mediaId, description, fluid } = rightMedia || {};
                return (
                  <div key={id}>
                    <React.Fragment>
                      {compileRichText({ textTheme: 'primary', size: MEDIUM, locale })(leftColumn)}
                    </React.Fragment>
                    <Img
                      alt={description}
                      fluid={fluid}
                      className="mobile-image"
                      key={mediaId}
                    />
                  </div>
                );
              })}
            </MobileContent>
          )}
        </PageContentWrapper>
      </PageWrapper>
    </Layout>
  );
};

export default BioTemplate;

export const bioPageQuery = graphql`
  query BioBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(pageUrl: { eq: $slug }) {
      ...PageMetadata
      contentEntry {
        ... on ContentfulBiography {
          id
          heroImage {
            description
            id
            fluid(quality: 90, maxWidth: 768) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          intro {
            raw
          }
          desktopContent {
            raw
          }
          desktopMedia {
            description
            id
            fluid(quality: 90, maxWidth: 768) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          title
          mobileContentAndMedia {
            id
            leftColumn {
              raw
            }
            rightMedia {
              description
              id
              fluid(quality: 90, maxWidth: 768) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
    globalSettings: contentfulGlobalSettings(language: { eq: $locale }) {
      ...GlobalSettings
    }
  }
`;
