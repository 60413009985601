import styled, { css } from 'styled-components';
import { createBreakpoint, fadeIn } from '@styles';

export const DesktopContentColumn = styled.div`
  display: none;

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: 55%;
    padding-right: ${theme.layout.gutter}px;
    display: block;
    margin-top: 130px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopMedium, css`
    width: 45%;
    padding-right: ${theme.layout.gutter}px;
    display: block;
    margin-top: 160px;
  `)}
`;

export const MobileContent = styled.div`
  width: 100%;

  .mobile-image {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  p {
    font-size: 18px;
    line-height: 1.9;
  }

  margin: 20px auto 80px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    display: none
  `)}
`;

export const DesktopImageColumn = styled.div`
  display: none;

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: calc(45% + 32px);
    right: -32px;
    position: relative;
    padding-left: ${theme.layout.gutter}px;
    display: block;
    margin-top: 100px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopMedium, css`
    width: calc(55% + 32px);
    right: -32px;
    position: relative;
    padding-left: ${theme.layout.gutter}px;
    display: block;
    margin-top: 100px;
  `)}
`;

export const DesktopImageWrapper = styled.div`
  margin-top: 40px;

  &:first-of-type {
    margin-top: 0px;
  }
`;

export const PageWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  width: 100%;
`;

export const PageContentWrapper = styled.div`
  margin: 45px auto;
  max-width: ${({ theme }) => theme.layout.extraWideMaxWidth}px;
  padding: 0px ${({ theme }) => theme.layout.gutter}px;
  flex-direction: row;
  display: flex;
  animation: ${fadeIn};
  animation-duration: 0.5s;
  animation-timing-function: ease;

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin: 0px auto 200px;
    flex-direction: row;
  `)}
`;
